.breadcrumb_container{
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    
    .container_breadcrumb{
        padding:0px;
        .breadcrumb_custom ol{
            list-style: none;
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: flex-end;
            li {
                font-weight: 700;
                color: $gray-600;
                font-size: 12px;
                text-transform: lowercase;
                a{
                    text-decoration: none;color: $gray-600;text-transform: lowercase;
                    &:hover{
                        color:$danger;
                    }
                }
                &.active {
                    color: $gray-500;
                }
            }
        }
    }
    &.withbg{
        margin-bottom: $margin_bottom_page_to_footer; 
        background: $gray-100;border-bottom: 1px solid $gray-100;
        .container_breadcrumb{
            padding-right: 15px;
        }
    }
}