.loader-full-page{
    position: fixed;width:100%;height: 100%;left:0;top:0;right:0;bottom:0;
    background-color: $gray-200;z-index:3;
    .container-loader{
        display: flex;align-items: center;justify-content: center;flex:1;
        flex-direction: column;height: 100%;
        .loader{
            margin-top:10px;margin-bottom:10px;
        }
        .datatext{
            margin-top:10px;font-size: 10pt;text-transform: capitalize;color:$secondary;
        }
    }
}