.my_footer{
    .footer_container{
        position: relative;
        background-color: rgba($color: $gray-600, $alpha: 0.6);
        width: 100%;
        color: $gray-600;font-size:12pt;
        .widget_container{
            .footer_widget{
                h2.title{
                    font-size: 13pt;color:$white;
                    margin:50px 0px 20px 0px;text-transform: uppercase;font-weight: bold;
                    @include media-breakpoint-down(sm) {
                        margin-top:$margin_bottom_page_to_footer;
                    }
                }
                .text{
                    text-align: left;
                }
                &.newsletter{
                    h2.title{
                        margin:20px 0px 20px 0px;
                    }

                    .myform_container{
                        position: relative;width: 100%;
                        input{
                            border:1px solid #FFF;
                            &::before,&::after{
                                &:focus,:active{
                                    outline: none;
                                }
                            }
                            
                        }
                        .button_container{
                            align-items: center;justify-content: center;display: flex;
                            .button_submit{
                                @include transition('all 0.3s ease-in');
                                display: flex;height: 100%;border-radius: 0px;
                                padding:$navbar-padding-y $navbar-padding-x;background-color:$danger;
                                text-decoration: none;margin:0px;color:$white;
                                i{font-size: 35pt;line-height: 1px;}
                                &[disabled],&:hover{background-color:rgba($color: $danger, $alpha: 0.6);color:rgba($color: $white, $alpha: 0.7);opacity: 1;}
                            }
                        }
                    }
                }

                ul.list_links{
                    list-style: none;margin:0px;padding: 0px;
                    li{
                        a{
                            text-transform: capitalize;
                            color: $gray-600;
                            text-decoration: none;
                            @include transition('all 0.3s ease-in');
                            &:hover{
                                color: $danger;
                            }
                        }
                        &.active{
                            a{
                                color:$danger;
                            }
                        }
                    }
                }
                .list_address_container{
                    position: relative;width:100%;
                    .list_address{
                        overflow: hidden;
                        margin-top: 7px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;
                        .icon {
                            font-size: 22px;
                            text-align: center;
                            margin-right: 10px;
                            color: $danger;
                            transition: .6s;
                            flex: 0 0 auto;
                            width: auto;
                            max-width: 100%;
                            align-items: center;
                        }
                        .content{
                            flex: 1 1;
                            display: flex;
                            justify-content: flex-start;
                            text-align: left;
                            a{
                                transition: .6s;
                                color:$gray-600;text-decoration:none;
                                &:hover{
                                    color: $danger;
                                }
                            }
                            p{
                                margin-bottom: 3px;
                            }
                        }
                    }
                }
                

                ul.list_hours{
                    list-style: none;margin:0px;padding: 0px;
                    li{
                        border-bottom: 1px solid $gray-600;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        .hours_info{
                            overflow: hidden;
                            margin-top: 15px;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            width: 100%;
                            .s_th{
                                flex: 1 1;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                            }
                            .s_td{
                                flex: 0 0 auto;
                                width: auto;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
            @include media-breakpoint-down(md) {
                .quicklink{
                    display: none;
                }
            }
        }

        .copyright_container{
            margin-top:30px;
            border-top: 1px solid rgba($color: $white, $alpha: 0.3);
            padding-top:7px;padding-bottom: 7px;
            .copyright,.developed{
                font-size:9pt;
                color:rgba($color: $white, $alpha: 0.5);
                transition: .6s;
                a{
                    transition: .6s;
                    color:rgba($color: $white, $alpha: 0.5);
                    text-decoration: none;
                    &:hover{
                        color:rgba($color: $white, $alpha: 0.7);
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .developed{margin-top: 7px;}
            }
        }
    }
}