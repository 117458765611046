.section-title{
   background-color: rgba($color: $gray-200, $alpha: 0.6);
   padding: $navbar-padding-y $navbar-padding-x;
   margin-bottom: $margin_bottom_page_to_footer; 
   &.nomarginbottom{
    margin-bottom: 0px; 
   }
   
   h1.title{
        font-size: 20pt;
        margin:0px;
        text-align: center;
        text-transform: capitalize;
        font-weight: 700;
   }
   .container_pdf{
       text-align: center;padding-top:4px;
       a{
            color:$danger;
            font-size:11pt;text-decoration:none;
            i{
                padding-right: 7px;
            }
            &:hover{
                color:rgba($color: $danger, $alpha: 0.7)
            }
       }
       
   }
}

.section-text{
    padding: $navbar-padding-y $navbar-padding-x;
    color:$black;
    font-size: 12pt;
    text-align: center;
    margin-bottom: $margin_bottom_page_to_footer;
    >div{
        p{
            &:last-child(){
                margin-bottom:0px;
            }
        }
    }
    
}