#CREATRIX {
    display: flex;
    width: 100%;
    min-height: 100vh;
}
  
img {
pointer-events: none;
-webkit-user-drag: none;
-khtml-user-drag: none;
-moz-user-drag: none;
-o-user-drag: none;
user-select: none;
}

.iscursor{
    cursor: pointer;
}

.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
 }

.main_bg1{
    background-image: url("../../../img/bg1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 50%;
}

.mytext{
    font-size: 13pt;color:$gray-600;
    table{
        width: 100% !important;overflow-x: auto;max-width: 100% !important;
        tr{
            margin-bottom: 10px !important;
            width: 100%!important;
            @include media-breakpoint-down(md) {
                display: table;
                padding: 0px 5px 0px 5px;
            }
            
            td{
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                vertical-align: top;
                iframe{max-width: 100%!important;width:100%!important;padding-left: 10px;padding-right: 10px;border:0px;outline :none;} 
                img{max-width: 100%;width: 100%;height:auto;padding-left: 10px;padding-right: 10px;}
                @include media-breakpoint-down(sm) {
                    display: table-row !important;
                    iframe{max-width: 100%!important;width:100%!important;padding:0px !important;margin-bottom:10px;height: 120px;padding-left: 10px;padding-right: 10px;border:0px;outline :none;} 
                    img{max-width: 100%;width: 100%;height:auto;padding: 0px !important;margin-bottom:10px;}
                    &:last-child(){
                        iframe{margin-bottom:0px;}
                        img{margin-bottom:0px;}
                    }
                }
               
            }
        }
    }
    iframe{
        display: block;
        width: 100%;
        border: 0px;

    }
}

blockquote {
    padding-left: 20px;padding-right: 20px;
    border-left: 5px solid $danger;
    font-style: italic;
    font-size: 14pt;
    padding-top: 20px;padding-bottom: 20px;
    background: $gray-100;
    color:$gray-600;
    h1.title{font-weight: 700;font-size: 18pt;}
    table{
        width: 100% !important;overflow-x: auto;max-width: 100% !important;
        tr{
            margin-bottom: 10px !important;
            width: 100%!important;
            td{
                vertical-align: top;
                iframe{max-width: 100%!important;width:100%!important;padding-left: 10px;padding-right: 10px;border:0px;outline :none;} 
                img{max-width: 100%;width: 100%;height:auto;padding-left: 10px;padding-right: 10px;}
                @include media-breakpoint-down(sm) {
                    display: table-row !important;
                    iframe{max-width: 100%!important;width:100%!important;padding:0px !important;margin-bottom:10px;height: 120px;padding-left: 10px;padding-right: 10px;border:0px;outline :none;} 
                    img{max-width: 100%;width: 100%;height:auto;padding: 0px !important;margin-bottom:10px;}
                    &:last-child(){
                        iframe{margin-bottom:0px;}
                        img{margin-bottom:0px;}
                    }
                }
               
            }
        }
    }
}

.image_container_box{
    display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;
}