.container_body{
    position:relative;
    min-height: 100%;
    width: 100%;
    @include transition(all 0.3s ease-in);
    //padding-top:$header_desktop_height;
    @include media-breakpoint-down(sm) {
    }
    @include media-breakpoint-only(md){
    }
    .body_page{
        display: flex;flex:1;width:100%;flex-direction:column;flex-wrap:wrap;min-height:100%;
        .my_header{
            flex: 0 0 auto;max-height: 100%;height: auto;
        }
        .content_data{
            display: flex;
            flex-direction: column;
            flex:1;
            width: 100%;min-height:150px;
            .loading_spinner{
                display: flex;flex:1;width: 100%;align-items: center;justify-content: center;flex-direction: column;
                .text_loading{margin-top: 7px;font-size:10px;}
            }
        }
        .my_footer{
            flex: 0 0 auto;max-height: 100%;width: 100%;height: auto;
        }
    }
}