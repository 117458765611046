
.btn{
    display: inline-flex;align-items: center;text-transform: uppercase;justify-content: center;
    i{@include paddingRight(7px);}
    .button_txt_spinner{@include paddingLeft(7px);text-transform: uppercase;font-style: italic;}
    &:focus,&:hover,&:active{
        outline: none!important;
        box-shadow: none;
    }
    &.onlyicon{
        i{@include paddingRight(0px);}
    }
    @include media-breakpoint-down(xs) {font-size:9.5pt;}
    @include media-breakpoint-down(sm) {
        &.hide_on_mobile{
            i{padding: 0px;}
            .button_info{display: none;}
        }
    }
}

.dropdown-toggle {
    &::after{
        [dir="ltr"] & {margin-left: $caret-spacing; margin-right: 0;}
        [dir="rtl"] & {margin-left: 0; margin-right: $caret-spacing;}
        
    }
}
.dropdown-item {@include TextLeft();}