.section-about{
    .row_container{
        margin-bottom: $margin_bottom_page_to_footer;
        .myimg{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
            @include media-breakpoint-down(md) {
                height: 300px;width: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        h1.title{
            color:$danger;font-size: 19pt;font-weight: 700;
            @include media-breakpoint-down(md) {
                margin-top:12px;
            }
        }
        &:last-child(){
            margin-bottom: $margin_bottom_page_to_footer;
        }
    }
 
    
}