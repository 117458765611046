.page-error-cms{
    position: relative;width: 100%;height:100%;
    padding: $navbar-padding-y $navbar-padding-x;
    .container_card{
        position: relative;
        text-align: center;
        .icon{
            margin-top:30px;
            display: block;
            color:$secondary;font-size: 90pt;
        }
        .text{
            padding-top:5px;
            font-size: 10pt;text-align: center;
            .url_link{
                color:$danger;font-weight: bold;font-style: italic;text-decoration: underline;
            }
        } 
        .button_home{
            margin-top:10px;
            display: flex;text-align: center;width: 100%;justify-content: center;
            i{
                padding-right: 7px;
            }
        }  
    }
    .copyright{
        padding-top:40px;
        font-size: 8pt;text-align: center;
    }
}