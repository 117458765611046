$header_desktop_height:75px;
$header_mobile_height:75px;
$margin_bottom_page_to_footer:30px;
@mixin TextLeft() {
    [dir="ltr"] & {text-align: left; }
    [dir="rtl"] & {text-align: right; }
}

@mixin TextRight() {
    [dir="ltr"] & {text-align: right; }
    [dir="rtl"] & {text-align: left; }
}

@mixin paddingLeft($property) {
    [dir="ltr"] & { padding-left: $property; }
    [dir="rtl"] & {padding-right: $property; }
}

@mixin paddingRight($property) {
    [dir="ltr"] & { padding-right: $property; }
    [dir="rtl"] & {padding-left: $property; }
}

@mixin marginLeft($property) {
    [dir="ltr"] & {margin-left: $property; }
    [dir="rtl"] & {margin-right: $property; }
}

@mixin marginRight($property) {
    [dir="ltr"] & {margin-right: $property; }
    [dir="rtl"] & {margin-left: $property; }
}

@mixin Left($property) {
    [dir="ltr"] & {left: $property; }
    [dir="rtl"] & {right: $property; }
}
@mixin Right($property) {
    [dir="ltr"] & {right: $property; }
    [dir="rtl"] & {left: $property; }
}

@mixin borderLeft($property) {
    [dir="ltr"] & {border-left: $property; }
    [dir="rtl"] & {border-right: $property; }
}
@mixin borderRight($property) {
    [dir="ltr"] & {border-right: $property; }
    [dir="rtl"] & {border-left: $property; }
}
@mixin displayFlex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
@mixin flexWrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

@mixin transition($property) {
    transition: $property;
    -moz-transition: $property;
    -webkit-transition: $property;
    -ms-transition: $property;
    -o-transition:$property;
    //all 500ms ease
    //all 0.3s ease-in;
    //all 0.5s;
    //all 0.3s ease
}