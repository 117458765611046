.section-ourwork{
   
    .col_ourwork_section{
        margin-bottom: $margin_bottom_page_to_footer;
        .single-project{
            text-align: center;cursor: pointer;height: 100%;
            &.project-second {
                position: relative;width:100%;
                .project-thumb {
                    overflow: hidden;
                    position: relative;
                    .image_container_box{
                        height:280px;
                        img {
                            height:100%;
                            width: 100%;
                            object-fit: cover;
                            object-position: center center;
                            transition: 1s;
                            @include media-breakpoint-down(sm) {
                                height:250px;
                                width: 100%;
                                object-fit: cover;
                                object-position: center center;
                            }
                        }
                    }
                    
                }
                .project-desc {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background: rgba($color: $gray-600, $alpha: 0.7);
                    color: $white;
                    
                    transition: .6s;
                    opacity: 0;
                    .project-desc-content {
                        text-align: center;transition: .6s;
                        padding: $navbar-padding-y $navbar-padding-x;
                        .summary_container {
                            line-height: 26px;
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                        a.read-more {
                            padding-top: $navbar-padding-y;padding-bottom: $navbar-padding-y;
                            text-decoration: none;
                            display: inline-block;
                            color: #fff;
                            font-weight: 700;
                            text-transform: uppercase;
                            border-radius: 50px;
                            transition: .6s;
                            background: $danger;
                            font-size: 12px;
                            padding-right: 30px;
                            padding-left: 30px;
                        }
                    }
                }

                &:hover{
                    .project-thumb {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .project-desc{
                        opacity: 1;
                    }
                }
            }        
        }
    }
    .infinity_scroll_footer{
        color:$gray-600;
        margin-bottom: 10px;
        .button_refresh_and_action{
            color:$gray-600;
        }
    }
    .loading_spinner{
        margin-bottom: $margin_bottom_page_to_footer;
    }
}

.section_ourwork_art{
    margin-bottom: $margin_bottom_page_to_footer;
    .summary_container{
        position: relative;width: 100%;
        h2.title{
            font-size:18pt;text-transform: capitalize;
            color:$gray-600;font-weight: 700;
        }
    }

    .project-details {
        padding: 50px;
        background: url('../../../img/pattern/pattern-2.png');
        color: #ffff;
        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
            padding: 20px;
        }
        h4.ourwork-heading {
            position: relative;
            padding-bottom: 20px;
            border-bottom: 1px solid $white;
            display: block;
            padding-right: 50px;
            margin-bottom: 30px;
            &::before{
                position: absolute;
                bottom: -2px;
                content: "";
                background: $white;
                height: 4px;
                width: 20px;
            }
        }
        ul {
            margin: 0px;list-style: none;padding: 0px;
            li {
                margin-bottom: 20px;
                text-transform: uppercase;
                span {
                    display: block;
                    color: $gray-100;
                    font-family: $font-family-ubuntu;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
            }
        }
    }
}