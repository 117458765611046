.block_services{
    position: relative;width: 100%;padding-top:$margin_bottom_page_to_footer;padding-bottom:35px;margin-bottom: $margin_bottom_page_to_footer;
    .info_details{
        position: relative;width: 100%;
        h2.title{text-align: center;margin: 0px 0px 20px 0px;color:$black;font-size:20pt;font-weight:700;text-transform: capitalize;}
        .summary{text-align: center;font-size:11pt;color:$gray-600;margin: 0px 0px 20px 0px;
            p{
                margin-bottom: 10px;
                &:last-child{
                    margin:0px;
                }
            }
        }
    }

    .slick-track{
    display: flex !important;
        .slick-slide{
            padding-left:$navbar-padding-x;
            height: inherit !important;
            >div{
                position: relative;height:100%;width:100%;
                .services-image-single {
                    height:100%;
                    transition: .6s;position: relative;width:100%;cursor: pointer;overflow: hidden;background-color:$gray-100;
                    .services-img {
                        transition: .6s;
                        position: relative;
                        margin-bottom: 10px;
                        overflow: hidden;
                        height: $height_image_services_thumb;
                        img{transition: .6s;height:$height_image_services_thumb;width: 100%;object-fit: cover;object-position: center center;}
                        .services-hover {
                            position: absolute;
                            bottom: -50px;
                            width: 100%;
                            transition: .6s;
                            .services-icon {
                                width: 100%;
                                display: block;
                                text-align: center;
                                padding-top: 7px;padding-bottom: 7px;
                                text-transform: uppercase;text-decoration: none;
                                font-weight: 500;
                                color: $white;
                                background-color:rgba($color: $danger, $alpha: 0.6) ;
                                i {
                                    padding-left: 10px;
                                }
                            }
                        }
                    }
                    .single-services {
                        margin-bottom: 0;padding:$navbar-padding-y $navbar-padding-x; transition: .6s;
                        .content {
                            h4.title {
                                transition: .6s;
                                margin-bottom: 10px;
                                font-size: 20px;
                                font-weight: 700;
                                color: $gray-600;
                            }
                            .summary {
                                transition: .6s;
                                color: $gray-600;
                                font-size: 14px;
                                line-height: 22px;
                            }
                            a.readmore{
                                display: block;
                                opacity:0;height: 0px;overflow: hidden;text-indent: -999px;
                            }
                        }
                    }
                    &:hover{
                        background-color:$gray-200;
                        .services-img {
                            img{transform: scale(1.1);}
                            .services-hover {
                                bottom: 0;
                            }
                        }
                        .single-services {
                            .content {
                                h4.title {
                                    color: $danger;
                                }
                                .summary {
                                    color: $black;
                                }
                            }
                        }
                        
                    }
                    
                }
            }
            &:first-child(){
                padding-left:0px;
            }
        }
    }

    .slick-dots{
        bottom: -35px;
        li {
            width: 25px;
            height: 20px;
            button{
                width: 25px;
                height: 20px;
                &::before{
                    font-size:20pt;
                    width: 25px;
                    height: 20px;
                    
                }
            }
        }
    }


    
    
}