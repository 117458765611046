.block_ourwork{
    position: relative;width: 100%;padding-top: $margin_bottom_page_to_footer;

    .info_details{
        position: relative;width: 100%;
        h2.title{text-align: center;margin: 0px 0px 20px 0px;color:$gray-600;text-transform: capitalize;
            font-weight: 700;font-size: 20pt;
        }
        .summary{text-align: center;font-size:11pt;color:$gray-600;margin: 0px 0px 20px 0px;
            p{
                margin-bottom: 10px;
                &:last-child{
                    margin:0px;
                }
            }
        }
    }
    .single-project{
        margin-bottom: $margin_bottom_page_to_footer;
        .project-thumb{
            position: relative;width: 100%;overflow: hidden;
            img{
                transition: 0.6s;
                height: 100%;width: 100%;object-fit: cover;max-height: 300px;object-position: center center;
            }
        }
        .project-desc-content{
            h4.title{font-size: 12pt;font-weight: 700;color:$gray-600;margin:8px 0px 10px 0px;transition: 0.6s;}
            .summary_container{font-size: 11pt;font-weight: 400;color:$gray-600;transition: 0.6s;}
            .read-more{opacity:0;height: 1px;text-indent: -999px;display: block;}
        }

        &:hover{
            .project-thumb{
                position: relative;width: 100%;overflow: hidden;
                img{
                    transform: scale(1.1);
                }
            }
            .project-desc-content{
                h4.title{color:$danger}
                .summary_container{color:$black;}
            }
        }
    }
    .button_container{
        margin-bottom: $margin_bottom_page_to_footer;
        .button_seeall{
            transition: 0.6s;
            background-color: $danger;border-radius: 20px;color:$white;text-decoration: none;font-size: 12pt;
            padding:10px 20px;
            i{font-size: 20pt;padding-right: 7px;}
            &:hover{
                background-color: rgba($color: $danger, $alpha: 0.6);
            }
        }
    }
    
}