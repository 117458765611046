@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot');
    src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.eot?#iefix') format("embedded-opentype"), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.woff') format("woff"), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.ttf') format("truetype"), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.2.0/fonts/fontawesome-webfont.svg?#fontawesomeregular') format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  .rc-menu {
    outline: none;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    //border: 1px solid #d9d9d9;
    //box-shadow: 0 0 4px #d9d9d9;
    //border-radius: 3px;
    color: $black;
  }
  
  .rc-menu-rtl {
    direction: rtl;
  }
  
  .rc-menu-hidden {
    display: none;
  }
  
  .rc-menu-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;
  }
  
  .rc-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  
  .rc-menu-item-group-title {
    color: #999;
    line-height: 1.5;
    padding: 8px 10px;
    border-bottom: 1px solid #dedede;
  }
  
  .rc-menu-item-active, .rc-menu-submenu-active > .rc-menu-submenu-title {
    background-color: #eaf8fe;
  }
  
  .rc-menu-item-selected {
    background-color: #eaf8fe;
    transform: translateZ(0);
  }
  
  .rc-menu-submenu-selected {
    background-color: #eaf8fe;
  }
  
  .rc-menu > li.rc-menu-submenu {
    padding: 0;
  }
  
  .rc-menu-horizontal.rc-menu-sub, .rc-menu-vertical.rc-menu-sub, .rc-menu-vertical-left.rc-menu-sub, .rc-menu-vertical-right.rc-menu-sub {
    min-width: 160px;
    margin-top: 0;
  }
  
  .rc-menu-item, .rc-menu-submenu-title {
    margin: 0;
    position: relative;
    display: block;
    padding: 7px 7px 7px 16px;
    white-space: nowrap;
  }
  
  .rc-menu-rtl {
    .rc-menu-item, .rc-menu-submenu-title {
      padding: 7px 16px 7px 7px;
    }
  }
  
  .rc-menu-item.rc-menu-item-disabled, .rc-menu-submenu-title.rc-menu-item-disabled, .rc-menu-item.rc-menu-submenu-disabled, .rc-menu-submenu-title.rc-menu-submenu-disabled {
    color: #777 !important;
  }
  
  .rc-menu > .rc-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    padding: 0;
    line-height: 0;
    background-color: #e5e5e5;
  }
  
  .rc-menu-submenu-popup {
    position: absolute;
    z-index: 1;
  
    .submenu-title-wrapper {
      padding-right: 20px;
    }
  }
  
  .rc-menu-submenu-rtl {
    &.rc-menu-submenu-popup .submenu-title-wrapper, .rc-menu-submenu-popup .submenu-title-wrapper {
      padding-right: 0;
      padding-left: 20px;
    }
  }
  
  .rc-menu-submenu > .rc-menu {
    background-color: #fff;
  }
  
  .rc-menu {
    .rc-menu-submenu-title .anticon, .rc-menu-item .anticon {
      width: 14px;
      height: 14px;
      margin-right: 8px;
      top: -1px;
    }
  }
  
  .rc-menu-rtl .rc-menu {
    .rc-menu-submenu-title .anticon, .rc-menu-item .anticon {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  
  .rc-menu-horizontal {
    //background-color: #f3f5f7;
    //border: none;
    //border-bottom: 1px solid #d9d9d9;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
  
    > {
      .rc-menu-item {
        padding: 15px 20px;
      }
  
      .rc-menu-submenu {
        > .rc-menu-submenu-title {
          padding: 15px 20px;
        }
  
        border-bottom: 2px solid transparent;
        display: inline-block;
        vertical-align: bottom;
      }
  
      .rc-menu-item {
        border-bottom: 2px solid transparent;
        display: inline-block;
        vertical-align: bottom;
      }
  
      .rc-menu-submenu-active, .rc-menu-item-active {
        border-bottom: 2px solid #2db7f5;
        background-color: #f3f5f7;
        color: #2baee9;
      }
    }
  
    &:after {
      content: '\20';
      display: block;
      height: 0;
      clear: both;
    }
  }
  
  .rc-menu-vertical, .rc-menu-vertical-left, .rc-menu-vertical-right, .rc-menu-inline {
    padding: 12px 0;
  }
  
  .rc-menu-vertical > .rc-menu-item, .rc-menu-vertical-left > .rc-menu-item, .rc-menu-vertical-right > .rc-menu-item, .rc-menu-inline > .rc-menu-item, .rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 12px 8px 12px 24px;
  }
  
  .rc-menu-rtl {
    &.rc-menu-vertical > .rc-menu-item, &.rc-menu-vertical-left > .rc-menu-item, &.rc-menu-vertical-right > .rc-menu-item, &.rc-menu-inline > .rc-menu-item, &.rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title, &.rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title, &.rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title, &.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
      padding: 12px 24px 12px 8px;
    }
  }
  
  .rc-menu-vertical .rc-menu-submenu-arrow, .rc-menu-vertical-left .rc-menu-submenu-arrow, .rc-menu-vertical-right .rc-menu-submenu-arrow, .rc-menu-inline .rc-menu-submenu-arrow {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    position: absolute;
    right: 16px;
    line-height: 1.5em;
  }
  
  .rc-menu-vertical .rc-menu-submenu-arrow:before, .rc-menu-vertical-left .rc-menu-submenu-arrow:before, .rc-menu-vertical-right .rc-menu-submenu-arrow:before, .rc-menu-inline .rc-menu-submenu-arrow:before {
    content: '\f0da';
  }
  
  .rc-menu-rtl {
    &.rc-menu-vertical .rc-menu-submenu-arrow:before, &.rc-menu-vertical-left .rc-menu-submenu-arrow:before, &.rc-menu-vertical-right .rc-menu-submenu-arrow:before, &.rc-menu-inline .rc-menu-submenu-arrow:before {
      content: '\f0d9';
    }
  }
  
  .rc-menu-submenu-rtl {
    .rc-menu-vertical .rc-menu-submenu-arrow:before, .rc-menu-vertical-left .rc-menu-submenu-arrow:before, .rc-menu-vertical-right .rc-menu-submenu-arrow:before, .rc-menu-inline .rc-menu-submenu-arrow:before {
      content: '\f0d9';
    }
  }
  
  .rc-menu-rtl {
    &.rc-menu-vertical .rc-menu-submenu-arrow, &.rc-menu-vertical-left .rc-menu-submenu-arrow, &.rc-menu-vertical-right .rc-menu-submenu-arrow, &.rc-menu-inline .rc-menu-submenu-arrow {
      right: auto;
      left: 16px;
    }
  }
  
  .rc-menu-submenu-rtl {
    .rc-menu-vertical .rc-menu-submenu-arrow, .rc-menu-vertical-left .rc-menu-submenu-arrow, .rc-menu-vertical-right .rc-menu-submenu-arrow, .rc-menu-inline .rc-menu-submenu-arrow {
      right: auto;
      left: 16px;
    }
  }
  
  .rc-menu-inline {
    .rc-menu-submenu-arrow {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  
    .rc-menu-submenu-open > .rc-menu-submenu-title .rc-menu-submenu-arrow {
      transform: rotate(-90deg);
    }
  }
  
  .rc-menu-vertical.rc-menu-sub, .rc-menu-vertical-left.rc-menu-sub, .rc-menu-vertical-right.rc-menu-sub {
    padding: 0;
  }
  
  .rc-menu-submenu-rtl {
    .rc-menu-vertical.rc-menu-sub, .rc-menu-vertical-left.rc-menu-sub, .rc-menu-vertical-right.rc-menu-sub {
      direction: rtl;
    }
  }
  
  .rc-menu-sub.rc-menu-inline {
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  
    > {
      .rc-menu-item, .rc-menu-submenu > .rc-menu-submenu-title {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 0;
      }
    }
  }
  
  .rc-menu-rtl .rc-menu-sub.rc-menu-inline > {
    .rc-menu-item, .rc-menu-submenu > .rc-menu-submenu-title {
      padding-left: 0;
    }
  }
  
  .rc-menu-open-slide-up-enter, .rc-menu-open-slide-up-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }
  
  .rc-menu-open-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }
  
  .rc-menu-open-slide-up-enter.rc-menu-open-slide-up-enter-active, .rc-menu-open-slide-up-appear.rc-menu-open-slide-up-appear-active {
    animation-name: rcMenuOpenSlideUpIn;
    animation-play-state: running;
  }
  
  .rc-menu-open-slide-up-leave.rc-menu-open-slide-up-leave-active {
    animation-name: rcMenuOpenSlideUpOut;
    animation-play-state: running;
  }
  
  @keyframes rcMenuOpenSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
  
    100% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
  }
  
  @keyframes rcMenuOpenSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
  
    100% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
  }
  
  .rc-menu-open-zoom-enter, .rc-menu-open-zoom-appear {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }
  
  .rc-menu-open-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }
  
  .rc-menu-open-zoom-enter.rc-menu-open-zoom-enter-active, .rc-menu-open-zoom-appear.rc-menu-open-zoom-appear-active {
    animation-name: rcMenuOpenZoomIn;
    animation-play-state: running;
  }
  
  .rc-menu-open-zoom-leave.rc-menu-open-zoom-leave-active {
    animation-name: rcMenuOpenZoomOut;
    animation-play-state: running;
  }
  
  .rc-menu-submenu-rtl {
    &.rc-menu-open-zoom-enter, &.rc-menu-open-zoom-appear, &.rc-menu-open-zoom-leave, .rc-menu-open-zoom-enter, .rc-menu-open-zoom-appear, .rc-menu-open-zoom-leave {
      transform-origin: top right !important;
    }
  }
  
  @keyframes rcMenuOpenZoomIn {
    0% {
      opacity: 0;
      transform: scale(0, 0);
    }
  
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  
  @keyframes rcMenuOpenZoomOut {
    0% {
      transform: scale(1, 1);
    }
  
    100% {
      opacity: 0;
      transform: scale(0, 0);
    }
  }