.my_header{
    .top-header{
        @include transition('all 1.3s ease-out');
        background-color: $danger;
        color: $white;
        font-family: 'Oxygen', sans-serif;
        padding: 5px 0;
        font-weight: 600;
        font-size: 15px;
        .contact_container{
            display: flex;flex-direction:row;width:100%;flex-wrap:wrap;
            justify-content: flex-start;
            align-items: center;
            @include media-breakpoint-down(sm) {
                &.email{
                    display: none;
                }
            }
            .contact_data{
                padding-right: 30px;
                
                @include media-breakpoint-down(sm) {
                    padding-right: 0px;
                    &.email{
                        display: none;
                    }
                }
                .content_container{
                    position: relative;display: flex;flex-direction:row;flex-wrap:wrap;justify-content: center;align-items: center;
                    .icon{
                        flex: 0 0 auto;
                        width: auto;
                        max-width: 100%;
                        padding-right:7px;
                        color:$white;
                        font-size:12pt;
                    }
                    .content{
                        flex: 1 1;
                        display: flex;
                        justify-content: flex-start;
                        color:$white;
                        font-size:10pt;
                        font-family: $font-family-ubuntu;
                        a{
                            transition: .6s;
                            color:$white;text-decoration:none;
                            &:hover{
                                
                                color: rgba($color: $white, $alpha: 0.7);
                            }
                        }
                        p{
                            margin-bottom: 3px;
                        }
                    }
                }
                &:last-child(){
                    padding-right: 0px;
                }
            }
        }
        .topbar-social{
            a{
                font-size:11pt;
                text-decoration:none;
                padding-right: 15px;
                transition: .6s;
                color:$white;
                &:last-child(){
                    padding-right: 0px;
                }
                &:hover{
                    color:rgba($color: $white, $alpha: 0.7);
                }
            }
        }
    }

    .mainnavbar_header{
        .navbar_brand{
            img{
                transition: .6s;
                height:60px;
                width: auto;
                @include media-breakpoint-down(sm) {
                    height:45px;
                }
            }
        }
        .recap-header-menu{
            padding:0px;margin:0px;color:$danger;
            &:focus,&:visited{
                outline: none;
            }
            i{
                font-size: 30pt;
            }
            .closebutton{display: block;}
            .openbutton{display: none;}
            &.collapsed{
                color:$gray-600;
                .closebutton{display: none;}
                .openbutton{display: block;}
            }
        }
        .menu_recap{
            @include media-breakpoint-up(sm) {
                margin-left: auto;
            }
            a{
                transition: .6s;
                color:$gray-600;
                text-transform: uppercase;
                font-size:12pt;font-weight:600;
                @include media-breakpoint-down(md) {
                    font-size:10pt;
                }
                &.nav-link{
                    margin-right:10px;
                    &:last-child(){
                        margin-right:0px;padding-right:0px;
                    }
                }
                &:hover{
                    color:rgba($color: $black, $alpha: 0.7)
                }
                &.active{
                    color:rgba($color: $danger, $alpha: 1)
                }
            }
            @include media-breakpoint-down(sm) {
                background-color: $gray-100;
                a{
                    padding: $navbar-padding-y $navbar-padding-x;transition: .6s;
                    color:$gray-600; text-transform: uppercase;font-size:13pt;font-weight:600;
                    border-bottom: 1px solid $white;
                    &.nav-link{
                        &:last-child(){
                            margin-right:0px;padding-right:0px;
                        }
                    }
                    &:hover{
                        color:rgba($color: $black, $alpha: 0.7)
                    }
                    &.active{
                        color:rgba($color: $danger, $alpha: 1)
                    }
                }
            }
        }

        &.sticky-menu {
            position: fixed;
            z-index: 5;
            width: 100%;
            background: $gray-100 !important;
            top: 0;
            transition: .6s;
            .navbar_brand{
                img{
                    transition: .6s;
                    height:45px;
                    width: auto;
                }
            }
        }
    }
    
}