$height_image_news_thumb:230px;
.section-news{
    .col_news_section{
        margin-bottom: $margin_bottom_page_to_footer;
        .single-news{
            &.list-news-item {
                height: 100%;cursor: pointer;background-color:$gray-200;transition: .6s;
                .image_container {
                    height: $height_image_news_thumb;
                    margin-bottom:10px;
                    position: relative;
                    overflow: hidden;
                    img{
                        transition: .6s;height:$height_image_services_thumb;width: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                .date_container {
                    .date {
                        transition: .6s;
                        float: none;
                        width: 60px;
                        background: #f2f2f2;
                        text-align: center;
                        margin-right: 20px;
                        position: absolute;
                        bottom: 0;
                        h4 {
                            transition: .6s;
                            margin-bottom: 0;
                            font-size: 18px;
                            color: $gray-600;
                            font-weight: 700;
                            line-height: 30px;
                            text-transform: capitalize;
                            span {transition: .6s;background-color: $danger;display: block;color: $white;}
                        }
                    }
                }
                .content_container{
                    padding: 0px $navbar-padding-x;
                    .info_container{
                        h2 {
                            margin-bottom: 2px;
                            font-size: 20px;
                            font-weight: 700;
                            color: $black;
                        }
                        .news-meta {
                            margin-bottom: 10px;
                            font-size:11px;
                            .news_meta_data {
                                padding-right: 10px;color: $gray-600;
                                span {margin-right: 7px;color: $danger;
                                }
                            }
                        }
                    }
                    
                    .summary_container{
                        transition: .6s;
                        color: $gray-600;
                        font-size: 14px;
                        line-height: 22px;
                    }
                    .read-more {
                        display: block;width: 100%;
                        margin-bottom: $navbar-padding-x;margin-top: $navbar-padding-x;
                        a{
                        text-decoration: none;
                        padding: 7px 15px;
                        display: inline-block;
                        background: $gray-600;
                        color: $white;
                        font-weight: 700;
                        font-size: 14px;
                        text-transform: uppercase;
                        border-radius: 50px;
                        transition: .6s;
                        }
                    }
                }
                
                &:hover{
                    background-color:$gray-100;
                    .image_container {
                        img{
                            transform: scale(1.1);
                        }
                    }
                    .date_container {
                        .date {
                            width: 80px;
                            h4 {
                                color: $danger;
                                span {background-color: $gray-600;display: block;color: $white;}
                            }
                        }
                    }
                    .content_container{
                        .info_container{
                            h2 {
                                color: $danger;
                            }
                        }
                        .summary_container{
                            color: $black;
                        }
                        .read-more {
                            a{
                                background: $danger;
                                color: $white;
                            }
                            
                        }
                    }
                }
            }
        }
    }

    .infinity_scroll_footer{
        color:$gray-600;
        margin-bottom: 10px;
        .button_refresh_and_action{
            color:$gray-600;
        }
    }
    
}

.section_news_art{
    margin-bottom: $margin_bottom_page_to_footer;
    .news-title{
        h2.title{
            font-size: 30px;
            font-weight: 700;
            color: $gray-600;
        }
        .news-meta {
            margin-bottom: 30px;
            display: flex;justify-content: flex-start;flex-direction:row;
            span{
                display: flex;justify-content: center;align-items: center;
                padding-right: 20px;
                color: $gray-600;
                font-size: 10pt;
                i{
                    font-size: 15pt;
                    color:$danger;
                    padding-right:5px;
                }
            }
        }
    }
}